
import { defineComponent, onMounted } from "vue";
import KTTableWidgetOtherIncomeExpenses from "@/components/widgets/tables/WidgetOtherIncomeExpenses.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "daily-cash-flow",
  components: {
    KTTableWidgetOtherIncomeExpenses,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Other Income & Expenses", ["Financial Statements","Income Statements"]);
    });
  },
});
