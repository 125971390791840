
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Information1 from "@/components/dropdown/Information1.vue";
import ElCurrencyInput from "@/components/CurrencyInput.vue";
import { ElLoading } from "element-plus";
import moment from "moment";
import { Action } from "vuex-module-decorators";
import { object } from "yup/lib/locale";
import ElInfo from "@/components/ElInfo.vue";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";

export default defineComponent({
name: "OtherIncomeExpense",
components: {
  Form,
  ElCurrencyInputNoSymbol,
},
async mounted() {
  this.init();
  this.initMonths();
},
data() {
  const schema = Yup.object({});

  const months = [
      "Jan",
      "Feb",
      "Mac",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
  ];
  const totalMonths = 0;

  const statements = {
      otherIncome: {
          label: "Other Income",
          child: {
            rentalIncome: {
                label: "Rental Income",
                value: [],
                total:0,
            },
            others: {
                label: "Others (To specify)",
                value: [],
                total:0,
            },
            interestIncome: {
                label: "Interest Income",
                value: [],
                total:0,
            },
          }
      },
      totalOtherIncome: {
          label: "Total Other Income",
          value: [],
          total:0,
      },
      otherExpenses: {
          label: "Other Expenses",
          child: {
            marketing: {
                label: "Marketing",
                value: [],
                total:0,
            },
            salaries: {
                label: "Salaries",
                value: [],
                total:0,
            },
            rental: {
                label: "Rental",
                value: [],
                total:0,
            },
            electricityWater: {
                label: "Electricity & Water",
                value: [],
                total:0,
            },
            storageFees: {
                label: "Storage Fees",
                value: [],
                total:0,
            },
            insurance: {
                label: "Insurance",
                value: [],
                total:0,
            },
            deliveryFees: {
                label: "Delivery Fees",
                value: [],
                total:0,
            },
            generalExpenses: {
                label: "General Expenses",
                value: [],
                total:0,
            },
            phoneInternet: {
                label: "Phone & Internet",
                value: [],
                total:0,
            },
          }
      },
      totalOtherExpenses: {
          label: "Total Other Expenses",
          value: [],
          total:0,
      },
     interestExpenses: {
          label: "Interest Expenses",
          child: {
            interestOverdraft: {
                label: "Interest-Overdraft",
                value: [],
                total:0,
            },
            interestTermLoans: {
                label: "Interest-Term loans",
                value: [],
                total:0,
            },
            interestOthers: {
                label: "Interest-Others",
                value: [],
                total:0,
            },
          }
     },
     totalInterestExpenses: {
          label: "Total Interest Expenses",
          value: [],
          total:0,
      },
  };

  return {
    schema:schema,
    months,
    totalMonths,
    statements,
  };
},
setup() {
  return {};
},
methods: {
  init() {
    setCurrentPageBreadcrumbs("Other Incomes and Expenses", ["Financial Statements","Income Statement"]);
  },
  onSubmit(values){
    console.log(values);
  },

  initMonths() {
      for (let index = 0; index < this.months.length; index++) {
          this.statements.totalOtherIncome.value.push(0);
          this.statements.totalOtherExpenses.value.push(0);
          this.statements.totalInterestExpenses.value.push(0);
      }

      for (let key of Object.keys(this.statements.otherIncome.child)) {
          let otherIncomeChild = this.statements.otherIncome.child;
          for (let index = 0; index < this.months.length; index++) {
              otherIncomeChild[key].value.push(0);
          }
      }

      for (let key of Object.keys(this.statements.otherExpenses.child)) {
          let otherExpensesChild = this.statements.otherExpenses.child;
          for (let index = 0; index < this.months.length; index++) {
              otherExpensesChild[key].value.push(0);
          }
      }

      for (let key of Object.keys(this.statements.interestExpenses.child)) {
          let interestExpensesChild = this.statements.interestExpenses.child;
          for (let index = 0; index < this.months.length; index++) {
              interestExpensesChild[key].value.push(0);
          }
      }
  },

  calculateTotalIncome() {
      for (let i = 0; i < this.statements.totalOtherIncome.value.length; i++) {
        this.statements.totalOtherIncome.value[i] = 0;
      }

      for (let keyChild of Object.keys(this.statements.otherIncome.child)) {
        let otherIncomeChild = this.statements.otherIncome.child[keyChild];
          let stateChildSubValue = otherIncomeChild.value;

          otherIncomeChild.total = stateChildSubValue.reduce((sum,item) => sum + item);

          for (let i = 0; i < this.statements.totalOtherIncome.value.length; i++) {
            this.statements.totalOtherIncome.value[i] += stateChildSubValue[i];
          }
      }
  },

  calculateTotalExpense() {
      for (let i = 0; i < this.statements.totalOtherExpenses.value.length; i++) {
        this.statements.totalOtherExpenses.value[i] = 0;
      }

      for (let keyChild of Object.keys(this.statements.otherExpenses.child)) {
        let otherExpensesChild = this.statements.otherExpenses.child[keyChild];
          let stateChildSubValue = otherExpensesChild.value;

          otherExpensesChild.total = stateChildSubValue.reduce((sum,item) => sum + item);

          for (let i = 0; i < this.statements.totalOtherExpenses.value.length; i++) {
            this.statements.totalOtherExpenses.value[i] += stateChildSubValue[i];
          }
      }
  },

  calculateTotalInterest() {
      for (let i = 0; i < this.statements.totalInterestExpenses.value.length; i++) {
        this.statements.totalInterestExpenses.value[i] = 0;
      }

      for (let keyChild of Object.keys(this.statements.interestExpenses.child)) {
        let interestExpensesChild = this.statements.interestExpenses.child[keyChild];
          let stateChildSubValue = interestExpensesChild.value;

          interestExpensesChild.total = stateChildSubValue.reduce((sum,item) => sum + item);

          for (let i = 0; i < this.statements.totalInterestExpenses.value.length; i++) {
            this.statements.totalInterestExpenses.value[i] += stateChildSubValue[i];
          }
      }
  },

},
});
